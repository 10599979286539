import { CSS, keyframes, styled } from '@leanpay/stitches'
import { IconName } from '@leanpay/utils'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { ReactNode } from 'react'

import { Box } from '../common'
import Flex from '../flex/flex'
import Icon from '../icon/icon'

interface DialogContentProps {
    children: ReactNode
    showCloseIcon?: boolean
    css?: CSS
}

export const DialogContent = ({ children, showCloseIcon = true, css }: DialogContentProps) => (
    <DialogPrimitive.Portal>
        <DialogPrimitiveOverlay />
        <DialogPrimitiveContent
            css={{
                borderRadius: '8px',
                ...css,
            }}
        >
            {showCloseIcon && (
                <Box css={{ position: 'absolute', right: '$2', top: '$1', paddingLeft: 10 }}>
                    <DialogPrimitive.Close asChild aria-label="Close">
                        <Box css={{ alignSelf: 'flex-end', cursor: 'pointer' }}>
                            <Icon iconName={IconName.CloseCircleIcon} />
                        </Box>
                    </DialogPrimitive.Close>
                </Box>
            )}
            <Flex css={{ flexDirection: 'column', flex: 1 }}>{children}</Flex>
        </DialogPrimitiveContent>
    </DialogPrimitive.Portal>
)

const overlayShow = keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
})

const contentShow = keyframes({
    '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
    '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
})

export const DialogPrimitiveOverlay = styled(DialogPrimitive.Overlay, {
    backgroundColor: '	rgb(53,64,82, .5)',
    position: 'fixed',
    inset: 0,
    animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    zIndex: 100,
})

export const DialogPrimitiveContent = styled(DialogPrimitive.Content, {
    'backgroundColor': 'white',
    'borderRadius': 4,
    'boxShadow': '0px 8px 26px rgba(53, 64, 82, 0.12)',
    'position': 'fixed',
    'top': '50%',
    'left': '50%',
    'transform': 'translate(-50%, -50%)',
    'width': '90vw',
    'maxWidth': '360px',
    'maxHeight': '85vh',
    'padding': '$2',
    'animation': `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    '&:focus': { outline: 'none' },
    'display': 'flex',
    'zIndex': 1000,
    '@sm': {
        maxWidth: '340px',
    },
})

export const DialogTrigger = styled(DialogPrimitive.Trigger, {
    cursor: 'pointer',
})
export const Dialog = DialogPrimitive.Dialog

export const DialogCloseTrigger = DialogPrimitive.Close
